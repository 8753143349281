@import 'config/variables';
@import 'components/teaser/mixins';
@import 'components/button/mixin';
@import 'components/image-teaser-legacy/mixin';

@import 'components/video-player/video-player';

@import '../../../../theme-creativeshop/src/components/image-teaser-legacy/image-teaser-legacy.scss';

.#{$ns}image-teaser-legacy {
    $root: &;

    &__nav-icon {
        fill: $color_primary-500;
    }
}
